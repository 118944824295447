
import i18n from "i18next";                      
import { initReactI18next } from "react-i18next";
import english from "../assets/locales/en/translation.json"
import polish from "../assets/locales/pl/translation.json"
import german from "../assets/locales/de/translation.json"
import spanish from "../assets/locales/es/translation.json"

i18n
  .use(initReactI18next)
  .init({
    lng: "pl",
    debug:true,
    resources: {
        en: {
            translation: english
              
        },
        pl: {
            translation: polish     
        },
        de: {
            translation: german
        },
        es: {
            translation: spanish
        }
    }
});

export default i18n;