import React from 'react';
import styled, { keyframes } from 'styled-components';
import WrapperPage from '../WrapperPage';

// Define the pulsating animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const PageWrapper = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const CategorySection = styled.div`
  margin-bottom: 40px;
`;

const CategoryTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 1.6rem;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
`;

const ActivityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column on mobile devices */
  }
`;

const ActivityCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
`;

// Add a specific class or styled component for the pulsating effect
const PulsatingCard = styled(ActivityCard)`
  animation: ${pulse} 2s infinite;
  background-color: #66FF99;
`;

const ActivityTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #007bff;
`;

const ActivityPrice = styled.div`
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  text-align: center;
`;

const PriceListPage = () => {
  return (
    <WrapperPage title='Cennik' href="/">
      {/* CENNIK Section */}
      <hr style={{color:'#007bff', marginBottom:'20px'}}/>
      <CategorySection>
        <ActivityGrid>
          <PulsatingCard>
            <ActivityTitle>Pierwsze bezpłatne zajęcia</ActivityTitle>
            <ActivityPrice>0 zł</ActivityPrice>
          </PulsatingCard>
          <ActivityCard>
            <ActivityTitle>Zajęcia jednorazowe</ActivityTitle>
            <ActivityPrice>50 zł</ActivityPrice>
          </ActivityCard>
        </ActivityGrid>
      </CategorySection>

      {/* ZAJĘCIA Z UMOWĄ Section */}
      <CategorySection>
        <CategoryTitle>Zajęcia z umową</CategoryTitle>
        <ActivityGrid>
          <ActivityCard>
            <ActivityTitle>4 razy w miesiącu</ActivityTitle>
            <ActivityPrice>140 zł</ActivityPrice>
          </ActivityCard>
          <ActivityCard>
            <ActivityTitle>8 razy w miesiącu</ActivityTitle>
            <ActivityPrice>270 zł</ActivityPrice>
          </ActivityCard>
        </ActivityGrid>
      </CategorySection>

      {/* ZAJĘCIA BEZ UMOWY Section */}
      <CategorySection>
        <CategoryTitle>Zajęcia bez umowy</CategoryTitle>
        <ActivityGrid>
          <ActivityCard>
            <ActivityTitle>4 razy w miesiącu</ActivityTitle>
            <ActivityPrice>160 zł</ActivityPrice>
          </ActivityCard>
          <ActivityCard>
            <ActivityTitle>8 razy w miesiącu</ActivityTitle>
            <ActivityPrice>300 zł</ActivityPrice>
          </ActivityCard>
        </ActivityGrid>
      </CategorySection>
    </WrapperPage>
  );
};

export default PriceListPage;
