import React from 'react';
import styled, { css } from 'styled-components';
import WrapperPage from '../WrapperPage';

// Pełna lista dni tygodnia
const daysOfWeek = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

const sampleSchedule = [
  {
    day: 'Wtorek',
    lessons: [
      {
        startTime: '16:30',
        endTime: '17:15',
        subject: 'Zdrowe Plecy',
        teacher: 'Agata Dębska',
        group: 'Grupa 1',
      },
      {
        startTime: '17:30',
        endTime: '18:15',
        subject: 'Zdrowe Plecy',
        teacher: 'Agata Dębska',
        group: 'Grupa 2',
      },
    ],
  },
  {
    day: 'Czwartek',
    lessons: [
      {
        startTime: '16:30',
        endTime: '17:15',
        subject: 'Język Angielski',
        teacher: 'Agnieszka Klimczak',
        group: '',
      },
      {
        startTime: '17:30',
        endTime: '18:15',
        subject: 'Język Chiński',
        teacher: 'Agnieszka Klimczak',
        group: '',
      },
    ],
  },
  {
    day: 'Sobota',
    lessons: [
      {
        startTime: null,
        endTime: null,
        subject: 'Cykliczne Warsztaty',
        teacher: '',
        group: 'Więcej informacji na Facebooku',
      },
    ],
  }
];

// Typy dla danych o zajęciach
interface Lesson {
  startTime: string;
  endTime: string;
  subject: string;
  teacher: string;
  group: string;
}

interface DaySchedule {
  day: string;
  lessons: Lesson[];
}

// Styled-components
const CalendarContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Dla responsywności */
  justify-content: space-around;
  margin: 5px;

  @media (max-width: 1000px) {
    flex-direction: column; /* Kolumny jedna pod drugą na małych ekranach */
  }
`;

const DayColumn = styled.div<{ isEmpty: boolean }>`
  width: ${({ isEmpty }) => (isEmpty ? '9%' : '15%')};
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: ${({ isEmpty }) => (isEmpty ? '3px' : '5px')};
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    width: 90%; /* Szerokość kolumny na urządzeniach mobilnych */
    margin: 0 auto 20px; /* Wyśrodkowanie i odstęp od dołu */
    text-align: center;
  }
`;

const DayHeader = styled.h3`
  text-align: center;
  color: #333;
`;

const LessonTile = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 20px 0;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LessonTime = styled.p`
  font-weight: bold;
  font-size: 14px;
`;

const LessonName = styled.p`
  margin: 5px 0;
  color: #666;
  font-size: 20px;
`;

const LessonGroup = styled.p`
  margin: 12px 0;
  color: #666;
  font-size: 16px;
`;

const BottomInfo = styled.div`
  height: 15px;
`;

const LessonTeacher = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: #333;
  text-align: right;
`;

const NoLessons = styled.p`
  color: #999;
  text-align: center;
  margin-top: 20px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #444;
`;

// Komponent CalendarPage
const CalendarPage: React.FC = () => {
  return (
    <WrapperPage title="Grafik" href='/'>
            <hr style={{color:'#007bff', marginBottom:'20px'}}/>
      <CalendarContainer>
        {daysOfWeek.map((day) => {
          // Znajdź zajęcia dla danego dnia
          const daySchedule = sampleSchedule.find((d) => d.day === day);
          const lessons = daySchedule ? daySchedule.lessons : [];
          const isEmpty = lessons.length === 0;

          return (
            <DayColumn key={day} isEmpty={isEmpty}>
              <DayHeader>{day}</DayHeader>
              <hr style={{color:'#e2e2e2'}}/>
              {lessons.length > 0 ? (
                lessons.map((lesson, index) => (
                  <LessonTile key={index}>
                    {lesson.startTime !== null && <LessonTime>
                      {lesson.startTime} - {lesson.endTime}
                    </LessonTime>}
                    <LessonName>{lesson.subject}</LessonName>
                    <LessonGroup>{lesson.group}</LessonGroup>
                    <BottomInfo>
                      <LessonTeacher>{lesson.teacher}</LessonTeacher>
                    </BottomInfo>
                  </LessonTile>
                ))
              ) : (
                <NoLessons>Brak zajęć</NoLessons>
              )}
            </DayColumn>
          );
        })}
      </CalendarContainer>
    </WrapperPage>
  );
};

export default CalendarPage;
