import React, { FC } from "react";
import WrapperPage from "../WrapperPage";

const NoFoundPage:FC<any> = () => {
    return(
        <WrapperPage title="Not Found" href="/">
            <div>
                404 - Page not Found
            </div>
        </WrapperPage>
    )
}

export default NoFoundPage