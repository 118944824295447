import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import FooterNavigation from "./components/FooterNavigation";
import styled from "styled-components";
import Root from "./Root";
import ParticipantFormPage from "./pages/form/ParticipantFormPage";

function App() {

  return (
    <Background>
      
    <Suspense fallback="loading">
      <Router>
        {/* <Header style={{background:'white'}}>
          <NavigatorBar />
        </Header> */}
        
        <Root/>
        <ParticipantFormPage/>
        <Footer style={{background:'#e6e6e6'}}>
          <FooterNavigation />
        </Footer>
      </Router>
      </Suspense>
      {/* Stopka */}
      <Footer>
        <p>© 2024 Kids Zone — Wszelkie prawa zastrzeżone.</p>
      </Footer>
      </Background>
  );
}

const Background = styled.div`
  background-color: #f6f6f6;
  border-radius: 15px;
`

const Footer = styled.footer`
  padding: 20px;
  text-align: center;
  background: #333;
  color: white;
`;

export default App;
