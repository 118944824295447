import React, { useEffect } from "react";
import styled from "styled-components";

// Styled component for the form container
const FormContainer = styled.div`
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const ParticipantFormPage = () => {
  useEffect(() => {
    // Tworzenie i wstawienie skryptu dynamicznie
    const script = document.createElement("script");
    script.src = 'https://app.activenow.io/external/signup_form/load_by_js?city_id=&code=5fkOLargb46oUwx1&proficiency_id=&school_id=12928&signup_form_id=124343&venue_id=&zz='
    script.async = true;
    script.type = 'text/javascript'

    const formContainer = document.getElementById("activenow-container-form");
    if (formContainer) {
      console.log('formContainer: ' + formContainer)
      formContainer.appendChild(script);
    }

    // Cleanup script on component unmount
    return () => {
      // if (formContainer && formContainer.contains(script)) {
      //   formContainer.removeChild(script);
      // }
    };
  }, []);

  // Wstawiamy HTML przy użyciu dangerouslySetInnerHTML
  return (
    <FormContainer>
      <div
        id="activenow-container-form"
        className="activenow-container-form"
      />
    </FormContainer>
  );
};

export default ParticipantFormPage;
