import React, { useLayoutEffect } from "react";
import {Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import NoFoundPage from "./pages/noFound/NoFoundPage";
import CalendarPage from "./pages/calendar/CalendarPage";
import ClassesPage from "./pages/classes/ClassesPage";
import TeachersPage from "./pages/projects/TeachersPage";
import PriceListPage from "./pages/priceList/PriceListPage";

const Root = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "smooth" });
    }, [location.pathname]);

    return(
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/classes" element={<ClassesPage />} />
          <Route path="/teachers" element={<TeachersPage />} />
          <Route path="/price_list" element={<PriceListPage />} />
          <Route path="*" element={<NoFoundPage />} />
        </Routes>
    )
}

export default Root