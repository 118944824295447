import Layout, { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React, { FC, ReactNode } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
interface WrapperPageProps {
  title: string;
  href: string;
  children: ReactNode;
}

const WrapperPage: FC<WrapperPageProps> = ({ title, href, children }) => {
    const navigate = useNavigate()
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 20px",
        }}
      >
        <FaCircleArrowLeft onClick={() => navigate(href)} style={{ marginRight: "auto", color:'#444', cursor:'pointer' }} size={"36px"} />
        <Title style={{ textAlign: "center", flex: 1, margin: 0 }}>
          {title}
        </Title>
        {/* Empty div to balance out the space for the centered title */}
        <div style={{ width: 24 }}></div>
      </div>
      <Content style={{ minHeight: "60vh" }}>{children}</Content>
    </Layout>
  );
};

export default WrapperPage;
