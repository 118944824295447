import React, { FC } from "react";
import styled from "styled-components";
import WrapperPage from "../WrapperPage";

// Importuj obrazy
import ZdrowePlecyImage from "../../assets/classes1.jpg"; // przykład ścieżki do obrazu
import AngielskiImage from "../../assets/classes2.jpg"; // przykład ścieżki do obrazu
import ChinskiImage from "../../assets/classes3.jpg"; // przykład ścieżki do obrazu

const ClassesPage: FC<any> = () => {
    return (
        <WrapperPage title="Zajęcia" href="/">
                  <hr style={{color:'#007bff', marginBottom:'20px'}}/>
            <CardsContainer>
                <Card>
                    <CardHeader>Zdrowe Plecy</CardHeader>
                    <CardImage src={ZdrowePlecyImage} alt="Zdrowe Plecy" />
                    <CardDescription>
                        Zajęcia skupiające się na wzmacnianiu mięśni pleców i poprawie postawy ciała.
                        Idealne dla osób z bólem pleców oraz dla tych, którzy chcą zadbać o zdrowy kręgosłup.
                    </CardDescription>
                </Card>
                <Card>
                    <CardHeader>Język Angielski</CardHeader>
                    <CardImage src={AngielskiImage} alt="Język Angielski" />
                    <CardDescription>
                        Kurs języka angielskiego, który pomoże Ci opanować umiejętności komunikacyjne.
                        Zajęcia są prowadzone w przyjaznej atmosferze przez doświadczonych nauczycieli.
                    </CardDescription>
                </Card>
                <Card>
                    <CardHeader>Język Chiński</CardHeader>
                    <CardImage src={ChinskiImage} alt="Język Chiński" />
                    <CardDescription>
                        Naucz się jednego z najbardziej popularnych języków na świecie. 
                        Kurs języka chińskiego dla początkujących i zaawansowanych z naciskiem na praktyczną komunikację.
                    </CardDescription>
                </Card>
            </CardsContainer>
        </WrapperPage>
    );
};

// Styled components
const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
`;

const Card = styled.div`
    width: 300px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }
`;

const CardHeader = styled.h3`
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 10px;
`;

const CardImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardDescription = styled.p`
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
`;

export default ClassesPage;
