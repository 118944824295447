import React, { FC } from "react";
import styled from "styled-components";
import WrapperPage from "../WrapperPage";
import photo3 from "../../assets/teacherOne.jpg";
import photo4 from "../../assets/teacherTwo.jpg";

// Updated teacher data with new descriptions
const teachers = [
  {
    id: 1,
    name: "Agata Dębska",
    description: "Jestem nauczycielką wychowania fizycznego, nauczania przedszkolnego i wczesnoszkolnego z terapią pedagogiczną. Od kilku lat pracuję z dziećmi, również jako nauczycielka anglojęzyczna. Moją pasją jest ruch, dlatego prowadzę zajęcia gimnastyki korekcyjnej i zdrowe plecy, aby móc dzielić się moim podejściem i zarażać zdrowymi nawykami. Uzupełniałam swoją wiedzę o kurs terapii ręki, kursy animacyjne oraz różne warsztaty dotyczące spektrum autyzmu i zespołu Aspergera. Wszystko to pozwala mi ulepszać moje zajęcia, dostosowywać je do danych grup i ich potrzeb, ponadto tworząc przestrzeń pełną dobrej energii i zabawy.",
    photoUrl: photo3,
  },
  {
    id: 2,
    name: "Agnieszka Klimczak",
    description:
      "Nauczyciel z wieloletnim doświadczeniem w pracy z dziećmi, posiadam tytuł magistra filologii angielskiej z chińskim oraz ukończone studia pedagogiczne i MBA. Moje zaangażowanie w rozwój uczniów jest wzbogacone licznymi kursami, takimi jak: integracja sensoryczna, terapia ręki, wspieranie rozwoju motorycznego dziecka, a także kurs instruktora pływania i sternika motorowodnego. Specjalizuję się również w zarządzaniu biznesem oraz diagnozie i terapii zespołu Aspergera w odniesieniu do zaburzeń autystycznych. Łącząc te umiejętności, tworzę inspirujące i wspierające środowisko nauki, które motywuje i rozwija dzieci w każdym aspekcie.",
    photoUrl: photo4,
  },
];

const TeacherCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  background: #f9f9f9;
  flex-direction: column; /* Default: stack vertically with photo first */
  text-align: center; /* Center align text for mobile */

  @media (min-width: 550px) {
    flex-direction: row; /* On larger screens, display details on the left and photo on the right */
    text-align: left; /* Align text to the left on larger screens */
  }
`;

const TeacherDetails = styled.div`
  flex: 1;
  order: 2; /* Details come second by default (below photo) on smaller screens */
  margin-top: 15px; /* Space between photo and details on smaller screens */

  @media (min-width: 550px) {
    order: 1; /* On larger screens, show details first (left side) */
    margin-right: 20px; /* Space between details and photo on larger screens */
    margin-top: 0; /* Remove top margin on larger screens */
  }
`;

const TeacherName = styled.h3`
  margin: 0;
  font-size: 1.2rem;
`;

const TeacherDescription = styled.p`
  margin: 5px 0 0;
  color: #555;
  font-size: 16px;
`;

const TeacherPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 1; /* Photo comes first by default (above details) on smaller screens */

  @media (min-width: 550px) {
    order: 2; /* On larger screens, show photo second (right side) */
  }
`;

const TeacherPhoto = styled.div<{ photoUrl: string }>`
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-image: url(${({ photoUrl }) => photoUrl});
  background-size: cover;
  background-position: center;

  @media (min-width: 550px) {
    margin-bottom: 0; /* Remove bottom margin on larger screens */
  }
`;

const TeachersPage: FC = () => {
  return (
    <WrapperPage title="Kadra" href="/">
            <hr style={{color:'#007bff', marginBottom:'20px'}}/>
      <div>
        {teachers.map((teacher) => (
          <TeacherCard key={teacher.id}>
            <TeacherPhotoContainer>
              <TeacherPhoto photoUrl={teacher.photoUrl} />
              <TeacherName>{teacher.name}</TeacherName> {/* Display name under the photo */}
            </TeacherPhotoContainer>
            <TeacherDetails>
              <TeacherDescription>{teacher.description}</TeacherDescription>
            </TeacherDetails>
          </TeacherCard>
        ))}
      </div>
    </WrapperPage>
  );
};

export default TeachersPage;
