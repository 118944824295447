import React, { FC } from "react";
import photo10 from "../../assets/photo10.jpg";
import photo1 from "../../assets/photo1.png";
import photo6 from "../../assets/photo6.jpg";
import photo7 from "../../assets/photo7.jpg";
import photo8 from "../../assets/photo8.png";
import photo9 from "../../assets/photo9.jpg";
import galleryPhoto1 from "../../assets/gallery_photo_1.jpg"
import galleryPhoto2 from "../../assets/gallery_photo_2.jpg"
import galleryPhoto3 from "../../assets/gallery_photo_3.jpg"
import galleryPhoto4 from "../../assets/gallery_photo_4.jpg"
import galleryPhoto5 from "../../assets/gallery_photo_5.png"
import galleryPhoto6 from "../../assets/gallery_photo_6.jpg"
import styled from 'styled-components'
import CenteredDivWithButton from "../../components/CenteredDivWithButton";
import { Col, Image, Row } from "antd";
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
       <CenteredDivWithButton/>
       <MainImageTile/>
      <Section>
        <h2>O nas</h2>
        <GridContainer>
          <ImageTile background={photo6} onClick={() => navigate("/classes")}>
            <h3>Zajęcia</h3>
            <figcaption>Sprawdź, które zajęcia będą idealne dla Twojego dziecka.</figcaption>
          </ImageTile>
          <ImageTile background={photo7} onClick={() => navigate("/teachers")}>
            <h3>Kadra</h3>
            <figcaption>Poznaj naszych instruktorów.</figcaption>
          </ImageTile>
          <ImageTile background={photo8} onClick={() => navigate("/calendar")}>
            <h3>Grafik</h3>
            <figcaption>Zobacz, kiedy do nas przyjść.</figcaption>
          </ImageTile>
          <ImageTile background={photo9} onClick={() => navigate("/price_list", {preventScrollReset: true})}>
            <h3>Cennik</h3>
            <figcaption>Skorzystaj z naszej oferty.</figcaption>
          </ImageTile>
        </GridContainer>
      </Section>
      <Section>
        <h2>Galeria</h2>
        <Gallery>
          <StyledImage width={350} height={200} src={galleryPhoto1} alt="Image 1" />
          <StyledImage width={350} height={200} src={galleryPhoto2} alt="Image 2" />
          <StyledImage width={350} height={200} src={galleryPhoto3} alt="Image 3" />
          <StyledImage width={350} height={200} src={galleryPhoto4} alt="Image 4" />
          <StyledImage width={350} height={200} src={galleryPhoto5} alt="Image 5" />
          <StyledImage width={350} height={200} src={galleryPhoto6} alt="Image 6" />
        </Gallery>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Maksymalnie 2 kolumny */
  gap: 20px; /* Odstęp między ImageTile */
  padding: 20px;
  
  @media (max-width: 550px) {
    grid-template-columns: 1fr; /* Na mniejszych ekranach jedna kolumna */
  }

  @media (min-width: 550px) {
    /* Przy szerokościach większych niż 550px */
    /* Tworzy parzystą liczbę kart w jednym wierszu */
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr)); 
  }
`;

const MainImageTile = styled.div`
  position: relative;
  background-image: url(${photo1});
  background-size: cover;
  background-position: center;
  color: white;
  margin-top: 20px;
  padding: 120px;
  border-radius: 8px;
  box-shadow: 5px 5px 9px -7px rgba(66, 68, 90, 1);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    z-index: 1;
  }

  h3, p {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 550px) {
    padding: 50px;
  }

  h3 {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
  }

  p {
    font-size: 15px;
    text-align: center;
  }
`;

interface ImageTileProps {
  background: string;
}

const ImageTile = styled.div<ImageTileProps>`
  position: relative;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  color: white;
  padding: 40px 20px;
  border-radius: 8px;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 9px -7px rgba(66, 68, 90, 1);
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    z-index: 1;
  }

  h3, figcaption {
    position: relative;
    z-index: 2;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
  }

  figcaption {
    font-size: 14px;
    font-weight: 550;
    text-align: center;
  }

  &:hover {
    opacity: 0.8;
    transform: scale(1.02);
  }
`;

const Section = styled.section`
  padding: 5px 0;
  text-align: center;

  h2 {
    font-size: 28px;
    margin-bottom: 20px;
    position: relative;
  }

  h2::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background: #333;
    margin: 10px auto 0;
  }
`;

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const StyledImage = styled(Image)`
  width: calc(33% - 10px);
  height: 200px;
  object-fit: cover;
  box-sizing: border-box;
  box-shadow: 5px 5px 9px -7px rgba(66, 68, 90, 1);
  border-radius: 15px;
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 5px;

  button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
`;

const SubscriptionSection = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;

  input {
    padding: 10px;
    width: 300px;
    margin-right: 10px;
  }

  button {
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
  }
`;

export default HomePage;
