import React from 'react';
import { Row, Col } from 'antd';
import styled, { keyframes } from 'styled-components';
import logo from "../assets/logo.png";
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  margin: 0;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Pozwala na pozycjonowanie tekstu */
`;

const Overlay = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Logo = styled.img`
  width: 100%;
  max-height: 450px; /* Maksymalna szerokość logo */
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 1px 1px 6px rgba(66, 68, 90, 1);
  
  @media (max-width: 768px) {
    max-height: 300px; /* Mniejsza szerokość dla tabletów i mniejszych urządzeń */
  }

  @media (max-width: 480px) {
    max-height: 200px; /* Jeszcze mniejsza szerokość dla bardzo małych urządzeń */
  }
`;

// Animacja pulsowania
const pulse = keyframes`
  0% {
    transform: scale(1);
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
  }
  50% {
    transform: scale(1.07);
    color: #ffcc00; /* Zmieniony kolor tekstu */
    background-color: rgba(0, 0, 0, 0.7); /* Zmieniony kolor tła */
  }
  100% {
    transform: scale(1);
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const InfoText = styled.div`
  position: absolute;
  bottom: 15px; /* 15px od dołu */
  left: 7%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 80%;
  animation: ${pulse} 5s infinite; /* Dodanie animacji pulsowania */

  @media (max-width: 768px) {
   font-size: 15px;
     left: 6%;
     bottom: 10px;
       padding: 6px 15px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
      left: 6%;
      bottom: 5px;
        padding: 4px 10px;
  }
`;

const CenteredDivWithButton = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Overlay>
        <Row gutter={16} align="middle" justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center', position: 'relative' }}>
            <Logo src={logo} alt="Logo" />
            <InfoText>10 oraz 12 września - Bezpłatne zajęcia pokazowe</InfoText>
          </Col>
        </Row>
      </Overlay>
    </Container>
  );
};

export default CenteredDivWithButton;
