import React, { FC } from "react";
import { Row, Col } from "antd";
import logo from "../assets/logo.png";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";

const initialData: FooterNavigationProps = {
  logo: logo,
  name: "Kids Zone",
  address: "05-400 Otwock",
  contactOne: "500-022-909",
  contactTwo: "506-363-791",
  email: "kontakt-kidzzone@outlook.com",
};

interface FooterNavigationProps {
  logo?: string;
  name?: string;
  address?: string;
  contactOne?: string;
  contactTwo?: string;
  email?: string;
}

const FooterNavigation: FC<FooterNavigationProps> = ({
  logo = initialData.logo,
  name = initialData.name,
  address = initialData.address,
  contactOne = initialData.contactOne,
  contactTwo = initialData.contactTwo,
  email = initialData.email,
}) => {
  return (
    <Row justify="center">
      <Col xs={24} sm={12} md={12} lg={16} xl={16}>
        <img
          src={logo}
          alt="Company Logo"
          style={{
            width: "100%",
            maxHeight: "300px",
            maxWidth: "500px",
            height: "auto",
            boxShadow: '1px 1px 4px rgba(66, 68, 90, 1)',
            borderRadius: 15,
          }}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <div style={{ textAlign: "center" }}>
          <Paragraph style={{ fontSize: 18, fontWeight: '600' }}>Kontakt</Paragraph>
          <Paragraph>{contactOne}</Paragraph>
          <Paragraph>{contactTwo}</Paragraph>
          <Paragraph style={{ fontSize: 18, fontWeight: '600' }}>Email</Paragraph>
          <Paragraph>{email}</Paragraph>
        </div>
      </Col>
    </Row>
  );
};

export default FooterNavigation;
